.ui.active.button{
    // color: white;
}

.buttonClass {
    // color: white !important;
    background-color: transparent !important;
    border: 1px solid rgb(0, 149, 255) !important;
    border-radius: 5px !important;
    margin: 5px !important;
}
