.footer {
    display: flex;
    width: 100%;
    margin-top: 20px;
    justify-content: space-around;
   height: 70px;
   background-color: transparent;
   align-items: center;
   backdrop-filter: blur(20px) !important;
   box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px !important;
   @media screen and (max-width: 700px) {
   flex-direction: column;
   justify-content: center;
  }
   &-iconContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    a {
        height: 35px;
        width: 35px;
        margin-right: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        color: white;
        font-size: 15px;
        background-color: rgb(123, 123, 123);
        &:hover {
            color: #dfdfdf;
        }
    }
   }

}