@import url(../node_modules/semantic-ui-css/semantic.min.css);

.backgroundBlur {
    /* backdrop-filter: blur(10px) !important; */
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
    border-radius: 10px !important;
    /* background-color: rgba(73, 105, 170, 0.415) !important; */
}

.ui.message{
    background-color: #ffffff24 !important;
}

.loaderContainer{
    backdrop-filter: blur(10px) !important;
    border-radius: 10px;
}