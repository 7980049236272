.landingContainer {
    height: 100%;
    z-index: 99999;
    &-textContainer {
        display: flex;
        flex-direction: column;
        // color: white;
        font-weight: 700;
        width: 100%;
        height: 100%;
        min-height: 400px;
        align-items: center;
        justify-content: center;
        line-height: 4rem !important;

        &-big {
            font-size: 6rem;
           margin-bottom: 20px;
           text-align: center;
           line-height: normal;
        }

        &-mid {
            font-size: 2rem;
        }
    }
}


/* CSS */
.button-80 {
  background: #25bde7;
  backface-visibility: hidden;
  border-radius: .375rem;
  border-style: solid;
  border-width: .125rem;
  box-sizing: border-box;
  color: #212121;
  cursor: pointer;
  display: inline-block;
  font-family: Circular,Helvetica,sans-serif;
  font-size: 1.125rem;
  font-weight: 700;
  letter-spacing: -.01em;
  line-height: 1.3;
  padding: .875rem 1.125rem;
  position: relative;
  text-align: left;
  text-decoration: none;
  transform: translateZ(0) scale(1);
  transition: transform .2s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  &:hover{
    color: white;
    border: 2px solid black;
  }
}

.button-80:not(:disabled):hover {
  transform: scale(1.05);
}

.button-80:not(:disabled):hover:active {
  transform: scale(1.05) translateY(.125rem);
}

.button-80:focus {
  outline: 0 solid transparent;
}

.button-80:focus:before {
  content: "";
  left: calc(-1*.375rem);
  pointer-events: none;
  position: absolute;
  top: calc(-1*.375rem);
  transition: border-radius;
  user-select: none;
}

.button-80:focus:not(:focus-visible) {
  outline: 0 solid transparent;
}

.button-80:focus:not(:focus-visible):before {
  border-width: 0;
}

.button-80:not(:disabled):active {
  transform: translateY(.125rem);
}