.resultContainer{
    background-color: #ffffff24 !important;
    backdrop-filter: blur(10px) !important;
    color: white !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ui.table{
    background-color: #ffffff7b !important;
    // color: white !important;
    // width: 50%;
    border-color: rgb(0, 174, 255);
    border-width: 2px;
    min-width: 300px;
}

.tableContainer{
    max-height: 65vh;
    overflow-y: auto;
    font-weight: 600;
    width: 100%;
    padding: 8px;
    font-size: 17px;
}