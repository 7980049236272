.text {
    // color: white !important;
}

.contentContainer {
    // border: solid 1px orange !important;
    border: none !important;
    background-color: transparent !important;
    // color: white !important;
}

.quiestionHeading {
    background-color: #ffffff24 !important;
    color: rgb(8, 7, 7) !important;
}
.content{
    width: 80%;
}


.optionContainer{
display: flex;
flex-direction: column;
&-option{
    color: black;
    font-size: 17px;
    padding: 15px;
    cursor: pointer;
    background-color: #e1e1e19f;
    margin: 5px 0px;
    &:hover {
        background-color: #0576e1 !important;
        color: white;
    }
    &-active{
        background-color: #0576e1 !important;
        color: white;
    }
    &-correct{
        background-color: green !important;
        color: white;
    }
    &-wrong{
        background-color: red !important;
        color: white;
    }
}


}

.loader-Question{
    width: 95%;
}

