body {
    overflow: hidden;
    height: 100vh;
   
  }
  
  section {
    height: 100vh;
    background: #ffff;
  }
  
  .item-check {
    position: absolute;
    z-index: 5;
    pointer-events: none;
    background: #fff;
    // animation: animate 5s linear infinite;
  }
  
  @keyframes animate {
    0% {
      transform: scale(0) translateY(0) rotate(0deg);
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      transform: scale(1) translateY(-500%) rotate(360deg);
      opacity: 0;
    }
  }

  .header-top{
    display: flex;
    padding: 0px 20px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
    width: 100%;
    background-color: transparent !important;
    backdrop-filter: blur(10px) !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px !important;
  }